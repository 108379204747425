<!-- 用户列表 -->
<template>
    <div class="mod-config">
        <el-form :inline="true" class="pageContainer" :model="dataForm" ref="userList"
            @keyup.enter.native="getDataList()" label-width="120px">
            <div class="top-space"></div>
            <el-form-item label="用户名或手机号:" prop="searchName">
                <el-input v-model="dataForm.searchName" placeholder="请输入" clearable></el-input>
            </el-form-item>
            <el-button class="my-btn" @click="getDataList(dataForm.searchName)" type="primary">查询</el-button>
            <el-button class="my-btn" @click="resetEvent('userList')">重置</el-button>
            <!-- <el-form-item label="手机号:" prop="searchPhone">
                <el-input v-model="dataForm.searchPhone" placeholder="请输入" clearable></el-input>
            </el-form-item> -->
        </el-form>

        <el-form :inline="true" class="pageContainer" :model="dataForm" @keyup.enter.native="getDataList()"
            label-width="100px">
            <el-table :data="dataList" border @selection-change="selectionChangeHandle"
                style="width: 100%; height: 100%; font-size: 16px">
                <el-table-column type="selection" header-align="left" align="left" width="50">
                </el-table-column>

                <el-table-column v-for="item in tableHeader" :key="item.prop" :prop="item.prop" :width="item.width"
                    :label="item.label" header-align="center" align="center">
                </el-table-column>

                <el-table-column fixed="right" header-align="left" align="left" min-width="260" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" style="font-size: 16px"
                            @click="rechargeEvent(scope.$index)">充值积分</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 对话框 -->
            <el-dialog :visible.sync="dataForm.dialogVisible" title="提示" width="30%" @close="dialogVisible = false">
                <span class="custom-tip">积分充值范围：10000-300000</span>
                <span slot="footer" class="dialog-footer">
                    <div class="select-container">
                        <el-select class="el-select-custom" v-model="dataForm.rechargeIntegralContent" @change="handleSelectChange"
                     clearable placeholder="请选择充值积分类型">
                        <el-option v-for="item in integralTypeList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                    </div>
                    <!-- <el-input class="custom-input" v-model="dataForm.giveIntegral" placeholder="请输入"
                        @input="handleInput" placeHolder="请输入赠送积分" clearable></el-input> -->
                    <!-- <p v-if="dataForm.errorMessage" class="error-message">{{ dataForm.errorMessage }}</p> -->
                    <el-button @click="cancelRechargeAction()">取 消</el-button>
                    <el-button type="primary" @click="sureRechargeAction()">确 定</el-button>
                </span>
            </el-dialog>

            <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
                :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </el-form>
    </div>
</template>

<script>
import { Message, MessageBox } from "element-ui";


export default {
    data() {
        return {
            dataForm: {
                outerVisible: false,
                innerVisible: false,
                dialogVisible: false,
                searchName: "",
                rechargeIndex: 0,
                selectedIntegralIndex: 0,
                rechargeIntegralContent: "",
                errorMessage: "",
            },
            tableHeader: [
                {
                    label: "用户Id",
                    prop: "id",
                    width: "100",
                },
                {
                    label: "用户名",
                    prop: "name",
                    width: "200",
                },
                {
                    label: "电话",
                    prop: "phone",
                    width: "200",
                },
                {
                    label: "积分",
                    prop: "integral",
                    width: "200",
                },
                {
                    label: "地址",
                    prop: "address",
                    width: "200",
                },
                {
                    label: "邀请码",
                    prop: "inviteCode",
                    width: "100",
                },
            ],
            dataList: [],
            integralTypeList: [],
            integralDataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListSelections: [],
        };
    },

    components: {},

    activated() {
        this.getDataList("");
        this.getIntegralList();
    },
    methods: {
        // 获取数据列表
        getDataList(userName) {
            var item = {
                pageNum: this.pageIndex,
                pageSize: this.pageSize,
            };
            console.log("请求参数userName：", userName);
            if (userName.length > 0) {
                item["content"] = userName;
            }
            console.log("请求参数：", item);
            this.api.account.user_list(item).then(({ data }) => {
                if (data && data.code === 200) {
                    this.dataList = data.data.list;
                    this.totalPage = data.data.total;

                    console.log("用户列表：", data);
                } else {
                    this.dataList = [];
                    this.totalPage = 0;
                }
            });
        },

        // 获取积分数据列表
        getIntegralList(userName) {
            this.api.account.integral_list({}).then(({ data }) => {
                if (data && data.code === 200) {
                    this.integralDataList = data.data;
                    this.integralTypeList = data.data.map(item => {
                        return `充值${item.integral}积分, 送${item.give}积分, 需${item.money}元`;
                    });;

                    console.log("积分列表：", data.data);
                }
            });
        },

        // 每页数
        sizeChangeHandle(val) {
            console.log("页数改变", val);
            this.pageSize = val;
            this.pageIndex = 1;
            this.getDataList("");
        },
        // 当前页
        currentChangeHandle(val) {
            console.log("当前页改变", val);
            this.pageIndex = val;
            this.getDataList("");
        },
        // 多选
        selectionChangeHandle(val) {
            console.log("多选改变", val);
            this.dataListSelections = val;
        },

        // 重置(内容)
        resetEvent(formName) {
            this.$refs[formName].resetFields();
            this.getDataList("");
        },

        // 充值积分
        rechargeEvent(index) {
            this.dataForm.dialogVisible = true;
            this.dataForm.rechargeIndex = index;
        },

        handleSelectChange(value) {
            // 通过value找到索引
            this.dataForm.selectedIntegralIndex = this.integralTypeList.findIndex(option => option === value);
            console.log('选中项的索引：', this.selectedIndex);
        },

        cancelRechargeAction(){
            this.dataForm.dialogVisible = false;
            this.dataForm.rechargeIntegralContent = '';
        },

        sureRechargeAction() {
            if (this.dataForm.rechargeIntegralContent.length == 0) {
                this.$message.error("充值积分不能为空");
                return;
            }
            MessageBox.alert(`确定选择${this.dataForm.rechargeIntegralContent}吗?`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                callback: (action) => {
                    if (action === 'confirm') {
                        this.confirmRechargeAction();
                    } else {
                        this.dataForm.rechargeIntegralContent = '';
                    }

                },
            });
        },

        confirmRechargeAction() {
            this.dataForm.rechargeIntegralContent = '';
            let item = this.dataList[this.dataForm.rechargeIndex];
            let integralItem = this.integralDataList[this.dataForm.selectedIntegralIndex];
            this.api.account
                .rechargeIntegral({
                    userId: item.id,
                    integral: integralItem.integral,
                })
                .then(({ data }) => {
                    if (data && data.code === 200) {
                        console.log("用户列表：", data);
                        this.dataForm.dialogVisible = false;
                        this.$message({
                            message: "充值成功",
                            type: "success", // 可以是 'success', 'warning', 'info', 'error'
                        });
                        this.getDataList(this.dataForm.searchName);
                    } else {
                    }
                });
        },

        handleInput() {
            const value = parseInt(this.dataForm.rechargeIntegral, 10);
            if (isNaN(value) || value < 1 || value > 100) {
                this.dataForm.rechargeIntegral = ""; // 清除非法输入
                this.dataForm.errorMessage = "请输入1-100的数字";
            } else {
                this.dataForm.errorMessage = ""; // 清除错误信息
            }
        },
    },
};
</script>

<style scoped>
.select-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-select-custom{
    align-items: center;
    /* margin-right: 20px; */
    margin-bottom: 20px;
}

.custom-tip {
    color: #666666;
    font-size: 14px;
}

.custom-input {
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
}

.top-space {
    margin-top: 10px;
}

.el-row {
    width: 100%;
    display: flex;
    justify-content: left;
}

.custom-switch-font .el-switch__label {
    font-size: 16px;
    /* 设置文字字号为16px */
}

.search-name {
    margin-left: 10px;
}

.my-btn {
    width: 100px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.my-max-btn {
    width: 150px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 20px;
}

.avatar {
    width: 30px;
    height: 30px;
}

.dialog-footer {
    text-align: right;
    padding: 10px;
}

.error-message {
    color: red;
    margin-top: 10px;
}

/* 覆盖 Element UI 的默认样式 */
.el-form-item__label {
    font-size: 16px;
    /* 设置你想要的字号大小 */
}
</style>
