import Vue from "vue";
import Router from 'vue-router'

import raceingHome from '@/views/modules/race/raceing/raceingHome.vue'
import raceSignUpHome from '@/views/modules/race/raceSignUp/raceSignUpHome.vue'

import raceingDetail from '@/views/modules/race/raceing/raceingDetail.vue'
import raceSignUpDetail from '@/views/modules/race/raceSignUp/raceSignUpDetail.vue'

import raceFinishHome from '@/views/modules/race/raceFinish/raceFinishHome.vue'

import awardExchanged from '@/views/modules/award/awardExchanged.vue'
import awardNeedExchange from '@/views/modules/award/awardNeedExchange.vue'

import inviteHome from '@/views/modules/invite/inviteHome.vue'

import login from '@/views/common/login.vue'

import mockStock from '@/views/modules/mockStock/mockStockHome.vue'
import mockStockToken from '@/views/modules/mockStock/mockStockRefreshToken.vue'

import userList from '@/views/modules/users/userList.vue'

import mockFutures from '@/views/modules/mockFutures/mockFutruresHome.vue'

import raceListHome from '@/views/modules/allRace/raceListHome.vue'

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            redirect: '/raceSignUpHome',
        },
        {
            path: '/raceingHome',
            name: 'raceingHome',
            component: raceingHome,
        },

        {
            path: '/raceSignUpHome',
            name: 'raceSignUpHome',
            component: raceSignUpHome,
        },

        {
            path: '/raceingDetail',
            name: 'raceingDetail',
            component: raceingDetail,
        },

        {
            path: '/raceSignUpDetail',
            name: 'raceSignUpDetail',
            component: raceSignUpDetail,
        },

        {
            path: '/raceFinishHome',
            name: 'raceFinishHome',
            component: raceFinishHome,
        },

        {
            path: '/awardExchanged',
            name: 'awardExchanged',
            component: awardExchanged,
        },

        {
            path: '/awardNeedExchange',
            name: 'awardNeedExchange',
            component: awardNeedExchange,
        },

        {
            path: '/inviteHome',
            name: 'inviteHome',
            component: inviteHome,
        },

        {
            path: '/login',
            name: 'login',
            component: login,
        },

        {
            path: '/mockStockHome',
            name: 'mockStockHome',
            component: mockStock,
        },

        {
            path: '/mockStockRefreshToken',
            name: 'mockStockToken',
            component: mockStockToken,
        },

        {
            path: '/userList',
            name: 'userList',
            component: userList,
        },

        {
            path: '/mockFutures',
            name: 'mockFutures',
            component: mockFutures,
        },

        {
            path: '/raceListHome',
            name: 'raceListHome',
            component: raceListHome,
        },
    ]
})