<template>
  <div class="main_style">

    <el-row>
      <el-col :span="3">
        <MainSide />
      </el-col>

      <el-col :span="21">
        <div>
          <MainNav/>
        </div>
        <MainContent/>
      </el-col>

    </el-row>
  </div>
</template>

<script>

import MainNav from './mainNav.vue'
import MainSide from './mainSide.vue'
import MainContent from './mainContent.vue'

export default {
  data() {
    return {
      // 刷新
      refresh() {
        this.$store.commit('common/updateContentIsNeedRefresh', true)
        this.$nextTick(() => {
          this.$store.commit('common/updateContentIsNeedRefresh', false)
        })
      }
    }
  },

  computed: {
    documentClientHeight: {
      get() { return this.$store.state.common.documentClientHeight },
      set(val) { this.$store.commit('common/updateDocumentClientHeight', val) }
    },
    sidebarFold: {
      get() { return this.$store.state.common.sidebarFold }
    },
    userId: {
      get() { return this.$store.state.user.id },
      set(val) { this.$store.commit('user/updateId', val) }
    },
    userName: {
      get() { return this.$store.state.user.name },
      set(val) { this.$store.commit('user/updateName', val) }
    }
  },

  components: {
    MainContent,
    MainNav,
    MainSide,
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.main_style {
  background-color: rgba(204, 207, 207, 0.409);
}

</style>
