// login
import account from './account'

// Race
import race from './race'

// 模拟股票
import mock_stock from './mock_stock'

//  模拟期货
import mock_futures from './mock_futures'


export default {
  race,
  account,
  mock_stock,
  mock_futures
}
