import httpRequest from '@/utils/httpRequest'
export default {
  
  raceList(params) {
    return httpRequest({
      url: '/race',
      method: 'get',
      params,
    })
  },

  raceSignUpUserList(params) {
    return httpRequest({
      url: '/bm/users/interior',
      method: 'get',
      params,
    })
  },

  searchStock(params) {
    return httpRequest({
      url: '/race/stock',
      method: 'get',
      params,
    })
  },

  checkStockPermitSelect(params){
    return httpRequest({
      url: '/race/stock-select/check',
      method: 'get',
      params,
    })
  },

  submitSignUp(params) {
    return httpRequest({
      url: '/race/register',
      method: 'post',
      data: params,
    },)
  },

  submitUpdateSignUp(params) {
    return httpRequest({
      url: '/bm/race/register/update',
      method: 'post',
      data: params,
    },)
  },

  modifyHold(params) {
    return httpRequest({
      url: '/race/stock/hold',
      method: 'post',
      data: params,
    },)
  },

  awardList(params) {
    return httpRequest({
      url: '/bm/users/reward',
      method: 'get',
      params,
    },)
  },

  awardExchange(params) {
    return httpRequest({
      url: '/bm/race/reward/exchange',
      method: 'post',
      data: params,
    },)
  },

  addRace(params) {
    return httpRequest({
      url: '/bm/race/add',
      method: 'post',
      data: params,
    },)
  },
  
}
