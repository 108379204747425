import httpRequest from '@/utils/httpRequest'
export default {
  
  mockFuturesRankList(params) {
    return httpRequest({
      url: '/futures/rank',
      method: 'get',
      params,
    })
  },

  futuresUpdateTime(params) {
    return httpRequest({
      url: '/race/time-update',
      method: 'get',
      params,
    })
  },
}
