import httpRequest from '@/utils/httpRequest'
export default {
  refreshToken(params) {
    return httpRequest({
      url: '/bm/stock-token',
      method: 'post',
      data: params || {}
    })
  },

  getTokenInfo(params) {
    return httpRequest({
      url: '/bm/stock-token',
      method: 'get',
      params
    })
  },

  mockStockRankList(params) {
    return httpRequest({
      url: '/stock/rank',
      method: 'get',
      params,
    })
  },

  stockUpdateTime(params) {
    return httpRequest({
      url: '/race/time-update',
      method: 'get',
      params,
    })
  },
}
