<!-- 模拟股票榜单 -->
<template>
    <div class="mod-config">
        <el-form :inline="true" class="pageContainer" :model="dataForm" ref="mockStockHomeInfo"
            @keyup.enter.native="getDataList()" label-width="100px">

            <el-row class="top-title">
                <el-col :span="20">
                    <div style="font-size: 20px; color: gray;">模拟股票排行榜</div>
                    <br/>
                    <div style="font-size: 13px; color: gray;">更新时间：{{dataForm.updateTime}}</div>
                </el-col>
                <el-col :span="3"> </el-col>
            </el-row>
            <el-table :data="dataList" border @selection-change="selectionChangeHandle"
                style="width: 100%; height: 100%; font-size: 16px;">
                <el-table-column type="selection" header-align="left" align="left" width="50">
                </el-table-column>

                <el-table-column v-for="item in tableHeader" :key="item.prop" :prop="item.prop" :width="item.width"
                    :label="item.label" header-align="center" align="center">

                </el-table-column>

            </el-table>

            <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
                :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </el-form>
    </div>
</template>

<script>

export default {
    data() {
        return {
            dataForm: {
                raceName: "",
                updateTime: "",
                outerVisible: false,
                innerVisible: false
            },
            tableHeader: [
                {
                    label: '排名',
                    prop: 'rank',
                    width: '100'
                },
                {
                    label: '昵称',
                    prop: 'name',
                    width: '200'
                },
                {
                    label: '总资产',
                    prop: 'assets',
                    width: '200'
                },
                {
                    label: '收益',
                    prop: 'incomeTotal',
                    width: '100'
                },
            ],
            roleList: [],
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListSelections: [],
        }
    },

    activated() {
        this.getDataList();
        this.getUpdateTime();
    },
    methods: {
        // 获取数据列表
        getDataList() {
            this.api.mock_stock
                .mockStockRankList({
                    pageNum: this.pageIndex,
                    pageSize: this.pageSize,
                })
                .then(({ data }) => {
                    if (data && data.code === 200) {
                        this.dataList = data.data.list
                        this.totalPage = data.data.total
                        console.log("比赛列表：", data)
                    } else {
                        this.dataList = []
                        this.totalPage = 0
                    }
                })
        },

         // 获取数据更新时间
         getUpdateTime() {
            this.api.mock_stock
                .stockUpdateTime({
                })
                .then(({ data }) => {
                    if (data && data.code === 200) {
                        this.dataForm.updateTime = data.data.stockUpdate
                        console.log("比赛更新时间：", data)
                    }
                })
        },

        // 每页数
        sizeChangeHandle(val) {
            console.log("页数改变", val);
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList()
        },
        // 当前页
        currentChangeHandle(val) {
            console.log("当前页改变", val);
            this.pageIndex = val
            this.getDataList()
        },
        // 多选
        selectionChangeHandle(val) {
            console.log("多选改变", val);
            this.dataListSelections = val
        },
    }
}
</script>

<style scoped lang="scss">
.top-title{
  margin-bottom: 20px;
}

.my-btn {
    width: 100px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 10px;
}

.my-max-btn {
    width: 150px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 20px;
}

.avatar {
    width: 30px;
    height: 30px;
}

/* 全局样式可以确保整个页面的高度被占据 */
html,
body,
#app {
    height: 100%;
    margin: 0;
    padding: 0;
}
</style>