<template>
    <el-menu class="el-menu-demo" background-color="#243144" text-color="#EBEBEB" active-text-color="#3489FF"
        default-active="raceSignUpHome" unique-opened @open="handleOpen" @close="handleClose" @select="handleSelect">
        <el-menu-item index="title" >
            <i class="el-icon-menu" style="color: #EBEBEB;"></i>
            <span slot="title" class="sys-title">黑马营管理系统</span>
        </el-menu-item>
        <div v-for="item in dataList">
            <el-submenu :default-active="submenuId" :index="item.id">
                <template slot="title">
                    <i :class="item.icon"></i>
                    <span>{{ item.name }}</span>
                </template>
                <div v-for="subItem in item.sub">
                    <el-menu-item :index="subItem.routerName" :key="subItem.routerName">
                        {{ subItem.name }}
                    </el-menu-item>
                </div>

            </el-submenu>
        </div>
    </el-menu>
</template>

<script>

export default {
    data() {
        return {
            isCollapse: true,
            menuId: '1',
            submenuId: '21',
            dataList: [
                // {
                //     id: "0",
                //     icon: 'el-icon-menu',
                //     name: "黑马营后台管理",
                //     sub: [

                //     ],
                // },

                {
                    id: "1",
                    icon: 'el-icon-s-management',
                    name: "选股大赛",
                    sub: [
                        {
                            id: "20",
                            routerName: 'raceingHome',
                            name: '进行中',
                        },
                        {
                            id: "21",
                            routerName: 'raceSignUpHome',
                            name: '报名中',
                        },
                        {
                            id: "22",
                            routerName: 'raceFinishHome',
                            name: '已结束',
                        },

                    ],
                },
                {
                    id: "2",
                    icon: 'el-icon-s-flag',
                    name: "比赛列表",
                    sub: [
                        {
                            id: "30",
                            routerName: 'raceListHome',
                            name: '全部比赛',
                        },
                    ],
                },
                {
                    id: "3",
                    icon: 'el-icon-present',
                    name: "获奖名单",
                    sub: [
                        {
                            id: "200",
                            routerName: 'awardNeedExchange',
                            name: '未兑换',
                        },
                        {
                            id: "201",
                            routerName: 'awardExchanged',
                            name: '已兑换',
                        },
                    ]
                },
                {
                    id: "4",
                    icon: 'el-icon-data-line',
                    name: "模拟股票",
                    sub: [
                        {
                            id: "300",
                            routerName: 'mockStockHome',
                            name: '榜单',
                        },
                        {
                            id: "301",
                            routerName: 'mockStockToken',
                            name: '刷新',
                        },
                    ]
                },
                {
                    id: "5",
                    icon: 'el-icon-trophy',
                    name: "模拟期货",
                    sub: [
                        {
                            id: "400",
                            routerName: 'mockFutures',
                            name: '榜单',
                        },
                    ]
                },
                {
                    id: "6",
                    icon: 'el-icon-s-grid',
                    name: "客户关系",
                    sub: [
                        {
                            id: "800",
                            routerName: 'inviteHome',
                            name: '推荐关系',
                        },
                    ]
                },
            ],
        }
    },

    computed: {
        sidebarLayoutSkin: {
            get() { return this.$store.state.common.sidebarLayoutSkin }
        },
        sidebarFold: {
            get() { return this.$store.state.common.sidebarFold }
        },
        menuList: {
            get() { return this.$store.state.common.menuList },
            set(val) { this.$store.commit('common/updateMenuList', val) }
        },
        menuActiveName: {
            get() { return this.$store.state.common.menuActiveName },
            set(val) { this.$store.commit('common/updateMenuActiveName', val) }
        },
        mainTabs: {
            get() { return this.$store.state.common.mainTabs },
            set(val) { this.$store.commit('common/updateMainTabs', val) }
        },
        mainTabsActiveName: {
            get() { return this.$store.state.common.mainTabsActiveName },
            set(val) { this.$store.commit('common/updateMainTabsActiveName', val) }
        }
    },

    components: {

    },

    created() {

        /* 创建一个广播通道 */
        const channelObj = new BroadcastChannel('showUserList');
        let that = this //主要代码
        // 监听消息事件
        channelObj.onmessage = function (event) {
            that.dataList = that.dataList.filter(item => item.name !== "用户");
            const newData = event.data;
            if (newData == true) {
                let item = {
                    id: "7",
                    icon: 'el-icon-user',
                    name: "用户",
                    sub: [
                        {
                            id: "500",
                            routerName: 'userList',
                            name: '用户列表',
                        },

                    ]
                };
                that.dataList.push(item);
            }


        };

    },

    mounted() {
        // 监听窗口大小变化以更新高度
        window.addEventListener('resize', this.handleResize);

    },

    beforeDestroy() {
        // 清除监听器
        window.removeEventListener('resize', this.handleResize);
    },

    methods: {
        handleResize() {
            this.screenHeight = window.innerHeight;
        },
        //模块打开
        handleOpen(index) {
            this.menuId = index;
            console.log('左侧菜单被打开', index);
        },
        //模块关闭
        handleClose() {

        },
        //菜单选中
        handleSelect(name) {
            console.log('选中数据', name);
            this.$router.push({
                name: name,
            })
        }

    },
}
</script>

<style scoped>
/* 设置主菜单背景色为黑色 */
.el-menu-demo {
    height: 100vh;
    /* width: 175px; */
}

.el-menu--collapse .el-menu .el-submenu,
.el-menu--popup {
    min-width: 50px !important;
    max-width: 175px !important;
}

.el-menu-item {
    min-width: 50px !important;
    max-width: 175px !important;
}

/* 设置子菜单背景色为灰色 */
.el-menu-item {
    background-color: #18222F !important;
}

/* 鼠标悬停时的背景色为绿色 */
.el-menu-item:hover {
    background-color: #161D29 !important;
}

/* 因为子菜单项在hover时也会应用.el-menu-item的样式，所以需要重新设置子菜单项的背景色 */
.el-menu-item.is-active {
    background-color: #18222F !important;
}

.sys-title{
    color: #EBEBEB;
}
</style>