var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mod-config"},[_c('el-form',{ref:"raceSignUpDetail",staticClass:"pageContainer",attrs:{"inline":true,"model":_vm.dataForm,"label-width":"100px"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getDataList()}}},[_c('el-row',{staticClass:"top-title"},[_c('el-col',{attrs:{"span":20}},[_c('div',{staticStyle:{"font-size":"20px","color":"gray"}},[_vm._v("比赛名称："+_vm._s(_vm.dataForm.raceName))])]),_c('el-col',{attrs:{"span":3}})],1),_c('el-table',{staticStyle:{"width":"100%","height":"100%","font-size":"16px"},attrs:{"data":_vm.dataList,"border":""},on:{"selection-change":_vm.selectionChangeHandle}},[_c('el-table-column',{attrs:{"type":"selection","header-align":"left","align":"left","width":"50"}}),_vm._l((_vm.tableHeader),function(item){return _c('el-table-column',{key:item.prop,attrs:{"prop":item.prop,"width":item.width,"label":item.label,"header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(item.prop === 'stock')?_c('div',_vm._l((scope.row.stock),function(item,index){return _c('el-autocomplete',{key:index,staticClass:"common",style:({
                marginBottom:
                  _vm.getCommonMarginBottom(scope.row.stock.length, index) + 'px',
              }),attrs:{"fetch-suggestions":_vm.querySearch,"placeholder":"选择股票"},on:{"select":(item) => _vm.handleSelectStock(item, scope.$index, index)},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{staticClass:"name-item"},[_vm._v(_vm._s(item))])]}}],null,true),model:{value:(item.stockName),callback:function ($$v) {_vm.$set(item, "stockName", $$v)},expression:"item.stockName"}})}),1):(item.prop === 'isHold')?_c('div',_vm._l((scope.row.stock),function(item,index){return _c('el-switch',{key:index,staticClass:"switch",style:({
                marginBottom:
                  _vm.getSwitchMarginBottom(scope.row.stock.length, index) + 'px',
              }),attrs:{"active-text":"持有","inactive-text":"不持有","active-color":"#13ce66","inactive-color":"#CCCCCC"},model:{value:(item.isHold),callback:function ($$v) {_vm.$set(item, "isHold", $$v)},expression:"item.isHold"}})}),1):(item.prop === 'register')?_c('div',[(scope.row.register === false)?_c('div',[_c('span',{staticStyle:{"color":"red"}},[_vm._v(" 未报名")])]):_c('div',[_c('span',{staticStyle:{"color":"orange"}},[_vm._v(" 已报名")])])]):_c('div',[_vm._v(" "+_vm._s(scope.row[item.prop])+" ")])]}}],null,true)})}),_c('el-table-column',{attrs:{"fixed":"right","header-align":"center","align":"center","min-width":"100","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticStyle:{"font-size":"16px"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.submitInfo(scope.$index)}}},[_vm._v("提交")])]}}])})],2),_c('el-pagination',{attrs:{"current-page":_vm.pageIndex,"page-sizes":[10, 20, 50, 100],"page-size":_vm.pageSize,"total":_vm.totalPage,"layout":"total, sizes, prev, pager, next, jumper"},on:{"size-change":_vm.sizeChangeHandle,"current-change":_vm.currentChangeHandle}}),_c('el-row',{staticClass:"el-row"},[_c('el-button',{staticClass:"my-btn",on:{"click":function($event){return _vm.backEvent()}}},[_vm._v("返回")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }