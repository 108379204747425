<!-- 模拟股票刷新token -->
<template>
  <div class="mod-config">

    <el-form :inline="false" class="pageContainer" :model="dataForm" ref="healthInfoDetailContent"
      @keyup.enter.native="getMockStockTokenEvent()" label-width="150px">


      <el-row class="el-row">
        <el-form-item label="模拟股票Token: " prop="addToken">
          <el-input v-model="dataForm.addToken" placeholder="请输入" clearable></el-input>
        </el-form-item>

        <el-button class="custom-btn" @click="addMockStockToken()" type="primary">添加</el-button>
      </el-row>
      <br />
      <el-col>
        <div class="title-style-system-item">
          Token信息:
        </div>
        <el-row>
          <div class="content-style-system-item">
            模拟股票Token:
          </div>
          <div class="content-style-system-item">
            {{ dataForm.mockStockToken }}
          </div>
        </el-row>
        <el-row>
          <div class="content-style-system-item">
            刷新时间:
          </div>
          <div class="content-style-system-item">
            {{ dataForm.refreshTime }}
          </div>
        </el-row>
      </el-col>


    </el-form>

  </div>
</template>

<script>

export default {
  data() {
    return {
      dataForm: {
        addToken: '',
        mockStockToken: "",
        refreshTime: '',

      },
    }
  },

  components: {

  },

  activated(){
    this.getMockStockTokenEvent();
  },

  methods: {

    addMockStockToken() {
      this.api.mock_stock.refreshToken({
        token: this.dataForm.addToken
      }).then(({ data }) => {
        if (data && data.code === 200) {
          this.getMockStockTokenEvent();
          console.log("添加token返回结果：", data);
        } else {

        }
      })
    },

    getMockStockTokenEvent() {
      this.api.mock_stock.getTokenInfo({

      }).then(({ data }) => {
        if (data && data.code === 200) {
          this.dataForm.mockStockToken = data.data["token"] ?? "";
          this.dataForm.refreshTime = data.data["time"] ?? "";
          console.log("获取token信息：", data);
        } else {

        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.pageContainer {
  height: 230px;
}


.desc-form {
  display: flex;
  width: 60px;
  margin-left: 20px;

  .el-form-item {
    margin-left: 40px;
    margin-bottom: 30px;
    white-space: 40px;
    width: 300px;
  }
}

.custom-button {
  margin-left: 10px;
  height: 50px;
}

.title-style-system-item {
  margin-left: 40px;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 400;
  color: #CCCCCC;
}

.content-style-bold {
  font-size: 20px;
  font-weight: 600;
  color: black;
}

.content-style-system {
  font-size: 14;
  font-weight: 400;
  color: rgb(188, 181, 181);
  display: flex;
  flex-direction: row;
  width: 900px;
}

.content-style-system-item {
  margin-left: 40px;
  margin-bottom: 30px;
  color: #999999;
}

.my-btn {
  width: 100px;
}

.custom-btn {
  width: 100px;
  height: 40px;
  margin-left: 20px;
}

.el-form-item-custom {
  margin-top: 20px;
  margin-left: 30px;
}

.form-custom {
  margin-left: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 30px;
}

.el-row {
  width: 100%;
  display: flex;
  justify-content: left;
}

.form_item_custom {
  margin-bottom: 10px;
}

.download-item {
  display: flex;
  align-items: center;
}

.download-name {
  width: 170px;
  //以下是字符串一行展示，多了截取
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.icon_style {
  cursor: pointer;
  color: blue; //#1310d8;
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-top: 10px;
}
</style>