<!-- 比赛首页 -->
<template>
    <div class="mod-config">
      <el-form
        :inline="true"
        class="pageContainer"
        :model="dataForm"
        ref="healthInfo"
        @keyup.enter.native="getDataList()"
        label-width="100px"
      >
  
        <el-table
          :data="dataList"
          border
          @selection-change="selectionChangeHandle"
          style="width: 100%; height: 100%; font-size: 16px;"
        >
          <el-table-column
            type="selection"
            header-align="left"
            align="left"
            width="50"
          >
          </el-table-column>
  
          <el-table-column
            v-for="item in tableHeader"
            :key="item.prop"
            :prop="item.prop"
            :width="item.width"
            :label="item.label"
            header-align="center"
            align="center">
            <template slot-scope="scope">
              <span v-if="item.prop == 'sex' && scope.row.sex">
                {{ scope.row.sex == "1" ? '男' : '女' }}</span
              >
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
  
          <el-table-column
            fixed="right"
            header-align="left"
            align="left"
            min-width="260"
            label="操作"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                style="font-size: 16px;"
                @click="openRaceInfo(scope.row)"
                >查看比赛详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
  
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageIndex"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalPage"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
        </el-form>
      </div>
  </template>
  
  <script>
  
  export default {
    data() {
      return {
        path: '/raceSignUpHome',
        dataForm: {
          raceName: "",        
          outerVisible: false,
          innerVisible: false
        },
        tableHeader: [
          {
            label: '比赛Id',
            prop: 'raceId',
            width: '100'
          },
          {
            label: '比赛名称',
            prop: 'name',
            width: '200'
          },
          {
            label: '比赛类型',
            prop: 'typeStr',
            width: '100'
          },
          {
            label: '比赛状态',
            prop: 'raceStatusStr',
            width: '100'
          },
          {
            label: '参赛人数',
            prop: 'raceNumber',
            width: '150'
          },
          {
            label: '报名开始时间',
            prop: 'registerStart',
            width: '150'
          },
          {
            label: '报名结束时间',
            prop: 'registerEnd',
            width: '150',
          },
        ],
        roleList: [],
        dataList: [],
        pageIndex: 1,
        pageSize: 10,
        totalPage: 0,
        dataListSelections: [],
      }
    },

    components: {

    },
    
    activated() {
      this.getDataList()
    },

    methods: {
      // 获取数据列表
      getDataList() {
        this.api.race
          .raceList({
            pageNum: this.pageIndex,
            pageSize: this.pageSize,
            raceStatus: 2,
          })
          .then(({ data }) => {
            if (data && data.code === 200) {
              this.dataList = data.data.list
              this.totalPage = data.data.total
              this.dataList.forEach(element =>{
                let typeStr = "";
                if(element.type == 1){
                  typeStr = "日赛";
                }else if(element.type == 2){
                  typeStr = "周赛";
                }else if(element.type == 3){
                  typeStr = "月赛";
                }
                element.typeStr = typeStr;
            
                element.raceStatusStr = "报名中";
              });
              console.log("比赛列表：",data)
            } else {
              this.dataList = []
              this.totalPage = 0
            }
          })
      },
      // 每页数
      sizeChangeHandle(val) {
        console.log("页数改变",val);
        this.pageSize = val
        this.pageIndex = 1
        this.getDataList()
      },
      // 当前页
      currentChangeHandle(val) {
        console.log("当前页改变",val);
        this.pageIndex = val
        this.getDataList()
      },
      // 多选
      selectionChangeHandle(val) {
        console.log("多选改变",val);
        this.dataListSelections = val
      },
  
      // 查看比赛详情
      openRaceInfo(row) {
        console.log("打印日志：",row);
        console.log("打印日志type：",row.type);
        this.$router.push({
          name: 'raceSignUpDetail',
          params: { 
            raceId: row.raceId,
            type: row.type,
            raceName: row.name
           }
        })
      },
    }
  }
  </script>
  
  <style scoped lang="scss">
  .my-btn {
    width: 100px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 10px;
  }
  
  .my-max-btn {
    width: 150px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 20px;
  }
  .avatar {
    width: 30px;
    height: 30px;
  }

  </style>
  