import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import cloneDeep from 'lodash/cloneDeep'
import '@/element-ui-theme'
//创建新工程第一步：需要import以下俩个element
import ElementUI from 'element-ui'//element组件
import 'element-ui/lib/theme-chalk/index.css'//element样式
import '@/assets/scss/index.scss'
import apiList from '@/api/index'
import httpRequest from './utils/httpRequest'

Vue.config.productionTip = false
//创建新工程第二步：全局使用element
Vue.use(ElementUI);
Vue.use(router);

// 挂载全局
Vue.prototype.$http = httpRequest //ajax请求方法
Vue.prototype.api = apiList //接口

//保存整站vuex本地存储初始状态
// window.SITE_CONFIG['storeState'] = cloneDeep(store.state)

new Vue({
  render: h => h(App),
  store,
  router,
}).$mount('#app')
