<!-- 比赛首页 -->
<template>
  <div class="mod-config">
    <el-form
      :inline="true"
      class="pageContainer"
      :model="dataForm"
      ref="raceSignUpDetail"
      @keyup.enter.native="getDataList()"
      label-width="100px"
    >
      <el-row class="top-title">
        <el-col :span="20" >
          <div style="font-size: 20px; color: gray;">比赛名称：{{ dataForm.raceName }}</div>
        </el-col>
        <el-col :span="3"> </el-col>
      </el-row>

      <el-table
        :data="dataList"
        border
        @selection-change="selectionChangeHandle"
        style="width: 100%; height: 100%; font-size: 16px;"
      >
        <el-table-column
          type="selection"
          header-align="left"
          align="left"
          width="50"
        >
        </el-table-column>

        <el-table-column
          v-for="item in tableHeader"
          :key="item.prop"
          :prop="item.prop"
          :width="item.width"
          :label="item.label"
          header-align="center"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="item.prop === 'stock'">
              <el-autocomplete
                class="common"
                v-for="(item, index) in scope.row.stock"
                :key="index"
                v-model="item.stockName"
                :fetch-suggestions="querySearch"
                placeholder="选择股票"
                @select="(item) => handleSelectStock(item, scope.$index, index)"
                :style="{
                  marginBottom:
                    getCommonMarginBottom(scope.row.stock.length, index) + 'px',
                }"
              >
                <template slot="default" slot-scope="{ item }">
                  <div class="name-item">{{ item }}</div>
                </template>
              </el-autocomplete>
            </div>

            <div v-else-if="item.prop === 'isHold'">
              <el-switch
                class="switch"
                v-for="(item, index) in scope.row.stock"
                :key="index"
                v-model="item.isHold"
                active-text="持有"
                inactive-text="不持有"
                active-color="#13ce66"
                inactive-color="#CCCCCC"
                :style="{
                  marginBottom:
                    getSwitchMarginBottom(scope.row.stock.length, index) + 'px',
                }"
              >
              </el-switch>
            </div>

            <!-- <div v-else-if="item.prop === 'analyse'">
              <el-input class="common" v-for="(item, index) in scope.row.stock" :key="index" v-model="item.analyse"
                placeholder="请输入个股分析"
                :style="{marginBottom: getCommonMarginBottom(scope.row.stock.length, index) + 'px'}"></el-input>
            </div> -->
            <div v-else-if="item.prop === 'register'">
              <div v-if="scope.row.register === false">
                <span style="color: red"> 未报名</span>
              </div>
              <div v-else="scope.row.register === true">
                <span style="color: orange"> 已报名</span>
              </div>
            </div>
            <div v-else>
              {{ scope.row[item.prop] }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          min-width="100"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              style="font-size: 16px;"
              @click="submitInfo(scope.$index)"
              >提交</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
      <el-row class="el-row">
        <el-button class="my-btn" @click="backEvent()">返回</el-button>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { MessageBox } from "element-ui";

export default {
  data() {
    return {
      dataForm: {
        raceName: "",
        raceId: 0,
        type: 1,
        outerVisible: false,
        innerVisible: false,
      },
      tableHeader: [
        {
          label: "用户Id",
          prop: "userId",
          width: "100",
        },
        {
          label: "用户名",
          prop: "name",
          width: "200",
        },
        {
          label: "股票名称代码",
          prop: "stock",
          width: "250",
        },
        {
          label: "是否持有",
          prop: "isHold",
          width: "250",
        },
        // {
        //   label: '个股分析',
        //   prop: 'analyse',
        //   width: "200",

        // },
        {
          label: "报名状态",
          prop: "register",
          width: "100",
        },
      ],
      roleList: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListSelections: [],
      searchData: [], // 模拟搜索结果列表数据
    };
  },

  mounted() {
    console.log("详情数据：", this.$route.params);
    console.log("详情数据RaceId：", this.$route.params.raceId);

    if (this.$route.params.raceId) {
      this.dataForm.raceId = this.$route.params.raceId;
    }
    if (this.$route.params.type) {
      this.dataForm.type = this.$route.params.type;
    }
    console.log("详情数据Type：", this.dataForm.type);
    if (this.$route.params.raceName) {
      this.dataForm.raceName = this.$route.params.raceName;
    }
    console.log("详情数据比赛名称：", this.dataForm.raceName);

    this.getDataList();
  },

  activated() {
    console.log("详情数据：", this.$route.params);
    console.log("详情数据RaceId：", this.$route.params.raceId);

    if (this.$route.params.raceId) {
      this.dataForm.raceId = this.$route.params.raceId;
    }
    if (this.$route.params.type) {
      this.dataForm.type = this.$route.params.type;
    }
    console.log("详情数据Type：", this.dataForm.type);
    if (this.$route.params.raceName) {
      this.dataForm.raceName = this.$route.params.raceName;
    }
    console.log("详情数据比赛名称：", this.dataForm.raceName);

    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.api.race
        .raceSignUpUserList({
          pageNum: this.pageIndex,
          pageSize: this.pageSize,
          raceId: this.dataForm.raceId,
        })
        .then(({ data }) => {
          if (data && data.code === 200) {
            this.dataList = data.data.list;
            console.log("比赛列表：", this.dataList);
            for (let i = 0; i < this.dataList.length; i++) {
              let map = this.dataList[i];
              if (map.stock == null) {
                map.stock = [];
                if (this.dataForm.type == 3) {
                  //月赛
                  for (let i = 0; i < 3; i++) {
                    map.stock.push({});
                  }
                } else {
                  //周赛、日赛
                  map.stock = [{}];
                }
              } else if (map.stock.length < 3) {
                if (this.dataForm.type == 3) {
                  //月赛
                  let needSupplementCount = 3 - map.stock.length;
                  for (let i = 0; i < needSupplementCount; i++) {
                    map.stock.push({});
                  }
                }
              }
            }
            this.totalPage = data.data.total;
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
        });
    },
    // 每页数
    sizeChangeHandle(val) {
      console.log("页数改变", val);
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      console.log("当前页改变", val);
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      console.log("多选改变", val);
      this.dataListSelections = val;
    },

    // 返回
    backEvent() {
      this.$router.back()
    },

    getSwitchMarginBottom(length, index) {
      if (index == length - 1) {
        return 0;
      } else {
        return 35;
      }
    },

    getCommonMarginBottom(length, index) {
      if (index == length - 1) {
        return 0;
      } else {
        return 20;
      }
    },

    //选股
    handleSelectStock(name, index, itemIndex) {
      console.log("Selected item:", name);

      this.api.race
        .checkStockPermitSelect({
          stock: this.getStockNumber(name),
        })
        .then(({ data }) => {
          console.log("搜索列表：", data.data);
          if (data) {
            if (data.code === 200) {
              this.dataList[index].stock[itemIndex].stockName = name;
              this.dataList[index].stock[itemIndex].stock =
                this.getStockNumber(name);
            } else if (data.code === 2000) {
              MessageBox.alert(data.message, "提示", {
                confirmButtonText: "确定",
                callback: (action) => {},
              });
            }
          }
        });
    },

    //搜索股票
    querySearch(queryString, cb) {
      console.log("搜索名称：", queryString);
      if (queryString) {
        this.api.race
          .searchStock({
            content: queryString,
          })
          .then(({ data }) => {
            console.log("搜索列表：", data.data);
            if (data && data.code === 200) {
              this.searchData = data.data;
              console.log("搜索列表2：", this.searchData);

              let results = [];
              for (let i = 0; i < this.searchData.length; i++) {
                let map = this.searchData[i];
                let stock = map.stock;
                let stockName = map.stockName;
                let result = `${stockName}(${stock})`;
                results.push(result);
              }

              cb(results);
            }
          });
      } else {
        cb([]);
      }
    },

    getStockNumber(inputString) {
      // 使用正则表达式匹配括号内的数字
      const regex = /\((\d+)\)/;
      const match = inputString.match(regex);
      return match ? match[1] : "";
    },

    // 提交
    submitInfo(index) {
      let map = this.dataList[index];
      let list = [];
      list = map.stock;

      list = list.filter((item) => {
        if ("stock" in item && item.stockName.length > 0) {
          return item;
        }
        // return Object.keys(item).length !== 0;
      });

      console.log("提交不能为空:", list);

      if (this.dataForm.type === 3) {
        //月赛
        if (list.length < 3) {
          MessageBox.alert("月赛必须提交三支股票", "提示", {
            confirmButtonText: "确定",
            callback: (action) => {},
          });
          return;
        }
      } else {
        //日赛、周赛
        if (list.length === 0) {
          MessageBox.alert("日赛、周赛提交必须提交一支股票", "提示", {
            confirmButtonText: "确定",
            callback: (action) => {},
          });
          return;
        }
      }

      list = list.filter((item) => {
        if ("isHold" in item) {
        } else {
          item.isHold = false;
        }
        return Object.keys(item).length !== 0;
      });

      if (map.register == true) {
        //已报名，更新
        for (let i = 0; i < list.length; i++) {
          let map = list[i];
          map.dataId = map.id;
        }
        this.api.race
          .submitUpdateSignUp({
            raceId: this.dataForm.raceId,
            data: list,
            tokenId: map.token,
          })
          .then(({ data }) => {
            console.log("提交返回结果：", data);
            if (data && data.code === 200) {
              console.log("提交成功");
              this.getDataList();
            } else {
              console.log("提交失败");
            }
          });
      } else {
        //未报名，提交
        this.api.race
          .submitSignUp({
            raceId: this.dataForm.raceId,
            data: list,
            tokenId: map.token,
          })
          .then(({ data }) => {
            console.log("提交返回结果：", data);
            if (data && data.code === 200) {
              console.log("提交成功");
              this.getDataList();
            } else {
              console.log("提交失败");
            }
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.top-title{
  margin-bottom: 20px;
}

.my-btn {
  width: 100px;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-right: 10px;
}

.my-max-btn {
  width: 150px;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-right: 20px;
}

.avatar {
  width: 30px;
  height: 30px;
}

.name-item {
  padding: 10px;
}

.common {
  display: flex;
  align-items: center; //垂直居中
  justify-content: center; //水平居中
  margin-bottom: 20px;
  /*添加底部间距 */
  height: 100%;
}

.switch {
  margin-bottom: 35px;
  /* 为每个 switch 添加底部间距 */
}

.el-row {
  width: 100%;
  display: flex;
  justify-content: center;
}

.my-btn {
  width: 100px;
}
</style>
