<template>
    <div class="body_style" :style="siteContentViewHeight">
        <keep-alive>
        <router-view />
      </keep-alive>

    </div>
</template>

<script>
import router from "@/router";

export default {
    data() {
        return {

        };
    },

    methods: {
        siteContentViewHeight() {
            var height = this.documentClientHeight - 80 - 30 - 2;
            if (this.$route.meta.isTab) {
                height -= 40;
                return isURL(this.$route.meta.iframeUrl)
                    ? { height: height + "px" }
                    : { minHeight: height + "px" };
            }
            return { minHeight: height + "px" };
        },
    },
    components: { router },
};
</script>

<style scoped lang="scss">
.body_style {
    display: flex;
    padding: 20px;
}
</style>
