<template>
  <div class="site-wrapper site-page--login">

    <div class="site-content__wrapper">
      <div class="site-content">
        <img style="width: 130px; height: 62px; border-radius: 10px;" src="./../../assets/img/hmy_ico.png" />
        <div class="login-main">
          <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
            status-icon>
            <el-row style="height: 50px;  margin-bottom: 20px; margin-top: 20px; color :#000000">
              <el-col :span="40" style="font-size: 20px">黑马营管理后台</el-col>
              <!-- <el-col :span="10"  :offset="12">欢迎登录</el-col> -->
            </el-row>
            <el-form-item prop="userName">
              <el-input class="login-input" v-model="dataForm.userName" prefix-icon="el-icon-user"
                placeholder="用户名"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input class="login-input" v-model="dataForm.password" prefix-icon="el-icon-lock" type="password"
                placeholder="密码"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button class="login-btn-submit" type="primary" @click="dataFormSubmit()">登录</el-button>
            </el-form-item>

            <el-row style="height: 30px;margin-bottom: 20px; margin-top: 20px; color :#999999">
              <el-col :span="6"><el-checkbox v-model="jzmm">记住密码</el-checkbox></el-col>
              <el-col :span="8" :offset="10">
                <el-popover placement="right" width="400" trigger="click">
                  <el-descriptions :colon="false" title="客服电话" style="margin-bottom: 20px;" :column="1">
                    <el-row slot="title" style="height: 30px;">
                      <el-col><img style="height: 30px;display: inline-block;margin-right: 10px"
                          src="../../assets/img/avatar.png" />客服电话</el-col>
                    </el-row>
                    <el-descriptions-item labelClassName="kf_item" label="客服1"> 电话：400-0333908
                      微信：******</el-descriptions-item>
                    <el-descriptions-item labelClassName="kf_item" label="客服2"> 电话：400-0333908
                      微信：******</el-descriptions-item>
                    <el-descriptions-item labelClassName="kf_item" label="热线电话"> 400-0333908</el-descriptions-item>
                  </el-descriptions>
                  <a style="margin-left: 60px;" slot="reference">联系我们</a>
                </el-popover>
              </el-col>
            </el-row>

          </el-form>
        </div>
      </div>
    </div>

    <div class="copyright">
      Copyright ©杭州金财聚富有限公司<a href="https://beian.miit.gov.cn/" target="_blank"
        style="display:inline-block;color: white;margin-left: 10px;">浙ICP备2023022935号-3A</a>
    </div>
  </div>
</template>

<script>

import Vue from 'vue';
import router from './../../router/index';

export default {
  data() {
    return {
      lxwmVisible: false,
      dataForm: {
        userName: '',
        password: ''
      },
      jzmm: false,
      dataRule: {
        userName: [
          { required: true, message: '帐号不能为空', trigger: 'blur' }
        ],
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }]
      }
      // captchaPath: ''
    }
  },
  created() {
    // this.getCaptcha()

  },
  methods: {
    lxwm() {
      this.lxwmVisible = true;
      console.log("3333")
    },

    requestInitApi() {
      this.api.account
        .init()
        .then(({ data }) => {
          console.log("init返回结果：", data)
          if (data && data.code === 200) {
            /* 创建一个广播通道 */
            const channelObj = new BroadcastChannel('showUserList');
              // 发送消息
              channelObj.postMessage(data.data.showUserList);
          } else {
            this.$message.error(data.msg)
          }
        });
    },

    // 提交表单
    dataFormSubmit() {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          this.api.account
            .login({
              phone: this.dataForm.userName,
              password: this.dataForm.password
            })
            .then(({ data }) => {
              console.log("登录返回结果：", data)
              if (data && data.code === 200) {
                sessionStorage.setItem('token', data.data.token)

                sessionStorage.setItem(
                  'userData',
                  JSON.stringify(data.data.userInfo)
                )
                Vue.prototype.$userData = data.data.userInfo
                this.$router.push({ name: 'raceSignUpHome' });
                this.requestInitApi();
              } else {
                this.$message.error(data.msg)
              }
            })
        }
      })
    }

  }
}
</script>

<style lang="scss">
.copyright {
  position: fixed;
  bottom: 10px;
  text-align: center;
  width: 100vw;
  color: white;
  z-index: 99;
}

.site-wrapper.site-page--login {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 1000;

  &:before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url(~@/assets/img/loginBg.png);
    background-size: cover;
  }

  .site-content__wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: transparent;
  }

  .site-content {
    min-height: 100%;
    padding: 30px;
  }

  .brand-info {
    margin: 30px 100px 0 90px;
    //margin: 0 auto;
    color: #fff;
  }

  .brand-info__text {
    margin: 0 0 22px 0;
    font-size: 48px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
  }

  .brand-info__intro {
    margin: 10px 0;
    font-size: 16px;
    line-height: 1.58;
    opacity: 0.6;
  }

  .info-main {
    border: 1px solid #cccccc;
    width: 800px;
    height: 400px;
    position: absolute;
    top: 10%;
    left: 5%;
    color: white;
  }

  .login-main {
    background: white;
    position: fixed;
    top: 40%;
    left: 80%;
    transform: translate(-50%, -50%);
    padding: 20px;
    width: 400px;
  }

  .login-title {
    margin: 0 auto;
    //width: 224px;
    height: 84px;
    -webkit-background-size: 100%;
    background-size: 100%;
    margin-bottom: 60px;
    color: #ffffff;
    font-size: 40px;
    width: 600px;
    position: fixed;
    left: -140px;
  }

  .login-captcha {
    overflow: hidden;

    >img {
      width: 100%;
      cursor: pointer;
    }
  }

  .login-btn-submit {
    width: 100%;
    margin-top: 38px;
    line-height: 24px;
    font-size: 16px;
    //color: #0094ff;
  }

  .login-input.el-input--medium .el-input__inner {
    line-height: 45px;
    height: 45px;
  }

  .login-input.el-input--medium .el-input__icon {
    line-height: 45px;
    font-size: 18px;
    color: #ffffff;
  }

  .login-input.el-input--medium .el-input__inner {
    background: transparent;
    border: 1px solid #f9fafc;
  }

  .login-input.el-input--prefix .el-input__inner {
    padding-left: 50px;
  }

  .login-input .el-input__prefix {
    left: 10px;
  }

}

.kf_item {
  margin-bottom: 20px;
  margin-left: 30px;
}
</style>
