<template>
    <el-cascader
      v-model="selectedOptions"
      :options="cascaderOptions"
      @change="handleCascaderChange"
      placeholder="请选择"
    ></el-cascader>
  </template>
   
  <script>
  export default {
    data() {
      return {
        selectedOptions: [],
        cascaderOptions: [
          {
            value: 'option1',
            label: '万达公司',
            children: [
              {
                value: 'subOption1',
                label: '张一'
              },
              {
                value: 'subOption1',
                label: '张二'
              },
              {
                value: 'subOption1',
                label: '张三'
              },
              {
                value: 'subOption1',
                label: '张四'
              },
              {
                value: 'subOption1',
                label: '张五'
              },
              {
                value: 'subOption1',
                label: '张六'
              },
            ]
          },
          {
            value: 'option2',
            label: 'Option 2',
            children: [
              {
                value: 'subOption2',
                label: 'Sub Option 2'
              }
            ]
          }
        ]
      };
    },
    methods: {
      handleCascaderChange(value) {
        console.log('Selected value:', value);
        // 处理级联选择器的变化
      }
    }
  };
  </script>