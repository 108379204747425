import httpRequest from '@/utils/httpRequest'
export default {
  login(params) {
    return httpRequest({
      url: '/account/login',
      method: 'post',
      data: params || {}
    })
  },

  logout(params) {
    return httpRequest({
      url: '/logout',
      method: 'post',
      data: params
    })
  },

  user_list(params) {
    return httpRequest({
      url: '/bm/users',
      method: 'get',
      params
    })
  },

  integral_list(params) {
    return httpRequest({
      url: '/users/integral/recharge',
      method: 'get',
      params
    })
  },

  rechargeIntegral(params){
    return httpRequest({
      url: '/bm/users/add-integral',
      method: 'post',
      data: params || {}
    })
  },

  init(params){
    return httpRequest({
      url: '/bm/init',
      method: 'get',
      params
    })
  },

}
