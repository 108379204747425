<!-- 比赛列表 -->
<template>
  <div class="mod-config">
    <el-form
      :inline="true"
      class="pageContainer"
      :model="dataForm"
      ref="healthInfo"
      @keyup.enter.native="getDataList()"
      label-width="100px"
    >
      <el-form
        :inline="true"
        class="pageContainer1"
        :model="dataForm"
        ref="raceListInfo"
        @keyup.enter.native="getDataList()"
        label-width="120px"
      >
        <div class="top-space"></div>

        <el-select
          v-model="dataForm.raceType"
          style="width: 150px; margin-right: 20px"
          clearable
          placeholder="请选择比赛类型"
        >
          <el-option
            v-for="item in raceTypeList"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="dataForm.month"
          style="width: 100px; margin-right: 20px"
          clearable
          placeholder="请选择月份"
        >
          <el-option
            v-for="item in monthList"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        <el-button class="my-btn" @click="getDataList()" type="primary"
          >查询</el-button
        >
        <el-button class="my-btn" @click="resetEvent('raceListInfo')"
          >重置</el-button
        >
        <el-button class="my-btn" @click="addRaceEvent()" type="primary">新增比赛</el-button>
        
      </el-form>

      <el-table
        :data="dataList"
        border
        @selection-change="selectionChangeHandle"
        style="width: 100%; height: 100%; font-size: 16px"
      >
        <el-table-column
          type="selection"
          header-align="left"
          align="left"
          width="50"
        >
        </el-table-column>

        <el-table-column
          v-for="item in tableHeader"
          :key="item.prop"
          :prop="item.prop"
          :width="item.width"
          :label="item.label"
          header-align="center"
          align="center"
        >
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </el-form>
    <addRaceDialog ref="addRaceInfo" @addRaceInfoCallback="addInfoCallbackEvent" :info-data="dataForm">
    </addRaceDialog>
  </div>
</template>

<script>
import addRaceDialog from '@/views/modules/allRace/addRaceDialog.vue'

export default {
  data() {
    return {
      dataForm: {
        raceName: "",
        raceType: "",
        month: "",
        outerVisible: false,
        innerVisible: false,
      },
      tableHeader: [
        {
          label: "比赛Id",
          prop: "raceId",
          width: "100",
        },
        {
          label: "比赛名称",
          prop: "name",
          width: "200",
        },
        {
          label: "比赛类型",
          prop: "typeStr",
          width: "100",
        },
        {
          label: "比赛状态",
          prop: "raceStatusStr",
          width: "100",
        },
        {
          label: "参赛人数",
          prop: "raceNumber",
          width: "150",
        },
        {
          label: "开始时间",
          prop: "raceStart",
          width: "150",
        },
        {
          label: "结束时间",
          prop: "raceEnd",
          width: "150",
        },
      ],
      roleList: [],
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      raceTypeList: ["日赛", "周赛", "月赛"],
      monthList: [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ],
      dataListSelections: [],
    };
  },

  activated() {
    this.getDataList();
  },
  components: {
    addRaceDialog,
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.api.race
        .raceList({
          pageNum: this.pageIndex,
          pageSize: this.pageSize,
          raceStatus: 3,
        })
        .then(({ data }) => {
          if (data && data.code === 200) {
            this.dataList = data.data.list;
            this.totalPage = data.data.total;
            this.dataList.forEach((element) => {
              let typeStr = "";
              if (element.type == 1) {
                typeStr = "日赛";
              } else if (element.type == 2) {
                typeStr = "周赛";
              } else if (element.type == 3) {
                typeStr = "月赛";
              }
              element.typeStr = typeStr;

              element.raceStatusStr = "已结束";
            });
            console.log("比赛列表：", data);
          } else {
            this.dataList = [];
            this.totalPage = 0;
          }
        });
    },
    // 每页数
    sizeChangeHandle(val) {
      console.log("页数改变", val);
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      console.log("当前页改变", val);
      this.pageIndex = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      console.log("多选改变", val);
      this.dataListSelections = val;
    },
    // 重置(内容)
    resetEvent(formName) {
      this.$refs[formName].resetFields();
      this.dataForm.raceType = "";
      this.dataForm.month = "";
      this.getDataList();
    },
    //添加比赛
    addRaceEvent() {
      var info = this.$refs['addRaceInfo']
      var data = info.dataForm
      data.userId = "11"
      data.userName = "张三"
      data.userDesc = "fewfewfwe"
      data.userAvatar = ""
      data.examinationDate = ''
      data.examinationOrg = ''
      data.examinationItem = ''
      data.examinationResult = ''
      data.examinationAnnex = ''
      data.outerVisible = true
    },

    // 添加比赛信息回调事件
    addInfoCallbackEvent(data) {
      let map = {
        raceName: data.raceName,
        raceType: data.raceType, 
        raceStartTime: data.raceStartTime, 
        raceEndTime: data.raceEndTime, 
        registerStartTime: data.registerStartTime, 
        registerEndTime: data.registerEndTime, 
      }
      console.log("比赛回调：",map);
      this.api.race.addRace(map).then(({ data }) => {
        if (data.code == 0) {
          this.$message({
            message: '添加成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.getDataList()
            }
          })
        }
      })
    },
  },
};
</script>

<style scoped lang="scss">
.my-btn {
  width: 100px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-right: 10px;
}

.my-max-btn {
  width: 150px;
  margin-bottom: 20px;
  margin-top: 10px;
  margin-right: 20px;
}

.avatar {
  width: 30px;
  height: 30px;
}

/* 全局样式可以确保整个页面的高度被占据 */
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
