import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import qs from 'qs'
import merge from 'lodash/merge'
import { clearLoginInfo } from '@/utils'


const tokenValue = 'f575831eb7b5444fadd4cc8039194603'
const vm = new Vue()
const errorMsg = {
  400: '请求错误',
  401: '未授权，请重新登录',
  403: '拒绝访问',
  404: '请求错误,未找到该资源',
  405: '请求方法未允许',
  408: '请求超时',
  500: '服务器端出错',
  501: '网络未实现',
  502: '网络错误',
  503: '服务不可用',
  504: '网络超时',
  505: 'http版本不支持该请求'
}

let loadingInstance = null

const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: true,
  // baseURL: 'http://192.168.110.234:8088', // 直连后端本地服务器
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    
    // 'X-Requested-With': 'XMLHttpRequest',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
    // 'Access-Control-Allow-Credentials': true,
  }
})

/**
 * 请求拦截
 */
http.interceptors.request.use(
  config => {
    console.log('token', sessionStorage.getItem('token')) //tokenValue
    if(sessionStorage.getItem('token') == null){
      console.log('token1', );
    }else{
      config.headers['token'] = sessionStorage.getItem('token') //sessionStorage.getItem('token') // 请求头带上token
    }
    config.url = '/api' + config.url // 增加前缀
    return config
  },
  error => {
    loadingInstance && loadingInstance.close()
    return Promise.reject(error)
  }
)

/**
 * 响应拦截
 */
http.interceptors.response.use(
  response => {
    console.log('response token', )
    if (response.data && response.data.code === 401) {
      // 1001, token失效
      clearLoginInfo()
      // router.push({ name: 'login' })
    }
    if (
      response.data &&
      response.data.code != '200'
    ) {
      if (response.data.code !== undefined) {
        vm.$notify({
          title: '温馨提示',
          message: response.data.message,
          position: 'top-right'
        })
      }
    }
    setTimeout(() => {
      loadingInstance && loadingInstance.close()
    }, 500)
    return response
  },
  error => {
    if (error && error.response) {
      if(error.response.status === 401){
        console.log("error.response: ",error.response);
        // 跳转到登录页面
        // window.location.href = '/login';
        router.push({ name: 'login' })
      }
      let errMsg = errorMsg[error.response.status]
      error.message = errMsg ? errMsg : `连接错误${error.response.status}`
    } else {
      error.message = '连接到服务器失败'
    }
    vm.$notify({
      title: '温馨提示',
      message: error.message,
      position: 'top-right'
    })
    setTimeout(() => {
      loadingInstance && loadingInstance.close()
    }, 500)
    return Promise.reject(error)
  }
)

function httpRequest(params) {
  if (
    params &&
    params.url &&
    params.url.indexOf('/erp/warning/tmsDeviceNum') == -1
  ) {
    loadingInstance = vm.$loading({
      text: '数据请求中...',
      background: 'rgba(255,255,255,.6)'
    })
  }
  return new Promise((resolve, reject) => {
    http(params).then(res => {
      loadingInstance && loadingInstance.close()
      resolve(res)
    })
  })
}
export default httpRequest
