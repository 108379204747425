<!-- 新增、编辑体检信息弹框 -->
<template>
  <el-dialog
    class="dialog-style"
    title="新增比赛"
    :visible.sync="dataForm.outerVisible"
  >
    <el-form
      :model="dataForm"
      :rules="rules"
      ref="addRaceInfo"
      label-width="120px"
      label-position="left"
      
      class="mod-config pageContainer"
    >
      <div>
        <el-form-item label="比赛名称：" prop="raceName">
          <el-input
            v-model="dataForm.raceName"
            placeholder="请输入比赛名称"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="比赛类型：" prop="raceType">
          <el-select
            v-model="dataForm.raceType"
            style="width: 220px; "
            clearable
            placeholder="请选择比赛类型"
          >
            <el-option
              v-for="item in raceTypeList"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="比赛开始时间：" prop="raceStartTime">
          <el-date-picker
              v-model="dataForm.raceStartTime"
              type="datetime"
              placeholder="开始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
        </el-form-item>

        <el-form-item label="比赛结束时间：" prop="raceEndTime">
          <el-date-picker
              v-model="dataForm.raceEndTime"
              type="datetime"
              placeholder="结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
        </el-form-item>

        <el-form-item label="报名开始时间：" prop="registerStartTime">
          <el-date-picker
              v-model="dataForm.registerStartTime"
              type="datetime"
              placeholder="开始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
        </el-form-item>

        <el-form-item label="报名结束时间：" prop="registerEndTime">
          <el-date-picker
              v-model="dataForm.registerEndTime"
              type="datetime"
              placeholder="结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
        </el-form-item>
      </div>
    </el-form>

    <div slot="footer" class="dialog-footer">
      <el-button @click="dataForm.outerVisible = false">取 消</el-button>
      <el-button type="primary" @click="sureEvent('addRaceInfo')"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>

export default {
  props: {
    infoData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    var validateHealth = (rule, value, callback) => {
      if (this.dataForm.fileList.totalCount == 0) {
        callback(new Error("入职体检附件不能为空"));
      } else {
        callback();
      }
    };
    return {
      dataForm: {
        raceName: "2024年5月17号日赛", //入职体检机构
        raceType: "", //入职体检项目
        raceStartTime: "", // 比赛开始时间
        raceEndTime: "", // 比赛结束时间
        registerStartTime: "", // 报名开始时间
        registerEndTime: "", // 报名结束时间
        outerVisible: false,
        innerVisible: false,
      },
      raceTypeList: ["日赛", "周赛", "月赛"],
      rules: {
        raceName: [
          {
            required: true,
            message: "比赛名称不能为空",
            trigger: ["blur", "change"],
          },
        ],
        raceType: [
          {
            required: true,
            message: "比赛类型不能为空",
            trigger: ["blur", "change"],
          },
        ],
        raceStartTime: [
          {
            required: true,
            message: "比赛开始时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
        raceEndTime: [
          {
            required: true,
            message: "比赛结束时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
        registerStartTime: [
          {
            required: true,
            message: "报名开始时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
        registerEndTime: [
          {
            required: true,
            message: "报名结束时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  components: {},

  activated() {},
  methods: {
    // 取消
    cancelEvent() {
      this.dataForm.outerVisible = false;
    },
    // 确认
    sureEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("addRaceInfoCallback", this.dataForm);
          this.dataForm.outerVisible = false;
          this.$refs[formName].resetFields();
        } else {
          this.$message("请将信息填写完整");
          return false;
        }
      });
    },
    // 数据传递
    modifyEvent(dataForm) {
      this.dataForm = dataForm;
    },
  },
};
</script>

<style scoped>

.desc-form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 20px;

  .el-form-item {
    margin-left: 40px;
    margin-bottom: 30px;
    white-space: 40px;
    width: 300px;
  }
}

.date-picker-style {
  width: 100%;
}

.dialog-style {
  width: 1100px;
}
</style>
