import router from '@/router'
import store from '@/store'

/**
 * 获取uuid
 */
export function getUUID () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth (key) {
  // return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
  return true
}

/**
 * 是否有按钮权限
 * @param {*} key
 */
export function isBtnAuth (key) {
  return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate (data, id = 'id', pid = 'parentId') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}

/**
 * 清除登录信息
 */
export function clearLoginInfo () {
  // Vue.cookie.delete('token')
  sessionStorage.setItem('token', null)
  store.commit('resetStore')
  router.options.isAddDynamicMenuRoutes = false
}

/**
 * 驼峰式转下横线
 */
export function toLowerLine (str) {
  let temp = str.replace(/[A-Z]/g, function (match) {
    return '_' + match.toLowerCase()
  })
  if (temp.slice(0, 1) === '_') { // 如果首字母是大写，执行replace时会多一个_，这里需要去掉
    temp = temp.slice(1)
  }
  return temp
}

/**
 * 下横线转驼峰式
 */
export function toCamel (str) {
  return str.replace(/([^_])(?:_+([^_]))/g, function ($0, $1, $2) {
    return $1 + $2.toUpperCase()
  })
}

export function deepCopy (obj, cache = []) {
  function find (list, f) {
    return list.filter(f)[0]
  }

  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  const hit = find(cache, c => c.original === obj)
  if (hit) {
    return hit.copy
  }

  const copy = Array.isArray(obj) ? [] : {}
  cache.push({
    original: obj,
    copy
  })

  Object.keys(obj).forEach(key => {
    copy[key] = deepCopy(obj[key], cache)
  })

  return copy
}
export function parseTime (time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  return format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
}

/*
　　interval: 单位 年/季/月/周/日
　　num: 数量
　　date: 指定日期   new Date()
*/
export function dateAdd (interval, num, date){
  let newDate = new Date(date)
  const number = Number(num);
  switch (interval) {
    case "年": {
      newDate.setFullYear(newDate.getFullYear() + number);
      return parseTime(newDate,'{y}-{m}-{d}');
    }
    case "季": {
      newDate.setMonth(newDate.getMonth() + number * 3);
      return parseTime(newDate,'{y}-{m}-{d}');
    }
    case "月": {
      newDate.setMonth(newDate.getMonth() + number);
      return parseTime(newDate,'{y}-{m}-{d}');
    }
    case "周": {
      newDate.setDate(newDate.getDate() + number * 7);
      return parseTime(newDate,'{y}-{m}-{d}');
    }
    case "日": {
      newDate.setDate(newDate.getDate() + number);
      return parseTime(newDate,'{y}-{m}-{d}');
    }
    default: {
      newDate.setDate(newDate.getDate() + number);
      return parseTime(newDate,'{y}-{m}-{d}');
    }
  }
}
