<!-- 奖励未兑换首页 -->
<template>
    <div class="mod-config">
        <el-form :inline="true" class="pageContainer" :model="dataForm" ref="healthInfo"
            @keyup.enter.native="getDataList()" label-width="100px">

            <el-form :inline="true" class="pageContainer1" :model="dataForm" ref="awardSearch"
                @keyup.enter.native="getDataList()" label-width="120px">
                <div class="top-space"></div>
                <!-- <el-autocomplete  style="width: 250px; margin-right: 20px;" v-model="dataForm.raceName"
                    :fetch-suggestions="querySearch" placeholder="请输入比赛名称"
                    @select="(item) => handleSelectRace(item, index)">
                    <template slot="default" slot-scope="{ item }">
                        <div class="name-item">{{ item }}</div>
                    </template>
                </el-autocomplete> -->

                <!-- <el-select v-model="dataForm.raceType" style="width: 150px; margin-right: 20px;" clearable
                    placeholder="请选择比赛类型">
                    <el-option v-for="item in raceTypeList" :key="item" :label="item" :value="item">
                    </el-option>
                </el-select> -->
                <el-select v-model="dataForm.userType" style="width: 150px; margin-right: 20px;" clearable
                    placeholder="请选择用户类型">
                    <el-option v-for="item in userTypeList" :key="item" :label="item" :value="item">
                    </el-option>
                </el-select>
                <el-select v-model="dataForm.month" style="width: 100px; margin-right: 20px;" clearable
                    placeholder="请选择月份">
                    <el-option v-for="item in monthList" :key="item" :label="item" :value="item">
                    </el-option>
                </el-select>
                <el-button class="my-btn" @click="getDataList()" type="primary">查询</el-button>
                <el-button class="my-btn" @click="resetEvent('awardSearch')">重置</el-button>
            </el-form>

            <el-table :data="dataList" border @selection-change="selectionChangeHandle"
                style="width: 100%; height: 100%; font-size: 16px;">
                <el-table-column type="selection" header-align="left" align="left" width="50">
                </el-table-column>

                <el-table-column v-for="item in tableHeader" :key="item.prop" :prop="item.prop" :width="item.width"
                    :label="item.label" header-align="center" align="center">
                    <template slot-scope="scope">
                        <span v-if="item.prop == 'userType'">
                            {{ scope.row.userType === 1 ? '外部' : '内部' }}
                        </span>
                        <span v-else-if="item.prop === 'isExchange'">
                            {{ scope.row.isExchange === true ? '已兑换' : '未兑换' }}
                        </span>
                        <span v-else-if="item.prop === 'isReceiveAccount'">
                            <el-switch v-model="scope.row.isReceiveAccount" class="custom-switch-font" active-text="账户"
                                inactive-text="现金" active-color="#13ce66" inactive-color="#CCCCCC"
                                @change="handleSwitchChange(scope.$index)">
                            </el-switch>
                        </span>
                        <span v-else>{{ scope.row[item.prop] }}</span>
                    </template>
                </el-table-column>

                <el-table-column fixed="right" header-align="left" align="left" min-width="260" label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" style="font-size: 16px;"
                            @click="exchangeEvent(scope.$index)">兑换</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 对话框 -->
            <el-dialog :visible.sync="dataForm.dialogVisible" title="提示" width="30%" @close="dialogVisible = false">
                <span>您确定兑换吗？</span>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dataForm.dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="confirmExchangeAction">确 定</el-button>
                </span>
            </el-dialog>

            <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
                :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="totalPage"
                layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </el-form>
    </div>
</template>

<script>
import { MessageBox } from 'element-ui';

export default {
    data() {
        return {
            dataForm: {
                userType: "",
                raceName: "",
                raceId: "",
                month: "",
                raceType: "",
                outerVisible: false,
                innerVisible: false,
                dialogVisible: false,
                exchangeIndex: 0,
            },
            tableHeader: [
                {
                    label: '用户Id',
                    prop: 'raceId',
                    width: '100'
                },
                {
                    label: '用户名',
                    prop: 'name',
                    width: '100'
                },
                {
                    label: '电话',
                    prop: 'phone',
                    width: '100'
                },
                {
                    label: '获奖理由',
                    prop: 'rewardReson',
                    width: '250'
                },
                {
                    label: '获奖时间',
                    prop: 'rewardTime',
                    width: '200'
                },
                {
                    label: '奖品信息',
                    prop: 'awardInfo',
                    width: '100'
                },
                {
                    label: '领取类型',
                    prop: 'isReceiveAccount',
                    width: '150',
                },
                {
                    label: '用户类型',
                    prop: 'userType',
                    width: '80'
                },
                {
                    label: '兑换状态',
                    prop: 'isExchange',
                    width: '80'
                },
            ],
            dataList: [],
            userTypeList: ["内部", "外部", "所有"],
            raceList: [],
            monthList: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
            raceTypeList: ["日赛", "周赛", "月赛"],
            searchData: [], // 模拟搜索结果列表数据
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListSelections: [],
        }
    },

    components: {

    },

    activated() {
        this.getDataList()
    },
    methods: {
        // 获取数据列表
        getDataList() {
            var item = {
                    pageNum: this.pageIndex,
                    pageSize: this.pageSize,
                    isExchange: false,
                };
            if(this.dataForm.month.length > 0){
                item["month"] = this.dataForm.month.replace('月', '');
            }
            if(this.dataForm.userType.length>0){
                if(this.dataForm.userType === "内部"){
                    item["userType"] = 0;
                }else if(this.dataForm.userType === "外部"){
                    item["userType"] = 1;
                }
            }
            this.api.race
                .awardList(item)
                .then(({ data }) => {
                    if (data && data.code === 200) {
                        this.dataList = data.data.list
                        this.totalPage = data.data.total
                        this.dataList.forEach(element => {
                            let info = `${element.cash}元`
                            if (element.account > 0) {
                                info = info + ` 或 ${element.account}万实盘账户`
                            }
                            element.awardInfo = info;
                        });
                        console.log("比赛列表：", data)
                    } else {
                        this.dataList = []
                        this.totalPage = 0
                    }
                })
        },
        // 每页数
        sizeChangeHandle(val) {
            console.log("页数改变", val);
            this.pageSize = val
            this.pageIndex = 1
            this.getDataList()
        },
        // 当前页
        currentChangeHandle(val) {
            console.log("当前页改变", val);
            this.pageIndex = val
            this.getDataList()
        },
        // 多选
        selectionChangeHandle(val) {
            console.log("多选改变", val);
            this.dataListSelections = val
        },

        // 重置(内容)
        resetEvent(formName) {
            this.$refs[formName].resetFields();
            this.dataForm.userType = '';
            this.dataForm.month = '';
            this.getDataList()
        },

        //选择领取类型
        handleSwitchChange(index) {
            let item = this.dataList[index];
            console.log("切换领取类型", item.isReceiveAccount);
            if (item.account === 0) {
                MessageBox.alert('无实盘账户，不能切换', '提示', {
                    confirmButtonText: '确定',
                    callback: action => {

                    }
                });
                item.isReceiveAccount = false;
            }
            if (item.isReceiveAccount === true) {
                item.receiveType = 2;
            } else {
                item.receiveType = 1;
            }
        },

        //搜索比赛
        querySearch(queryString, cb) {
            console.log("搜索名称：", queryString);
            if (queryString) {
                this.api.race
                    .searchStock({
                        content: queryString,
                    })
                    .then(({ data }) => {
                        console.log("搜索列表：", data.data);
                        if (data && data.code === 200) {
                            this.searchData = data.data;
                            console.log("搜索列表2：", this.searchData);

                            let results = [];
                            for (let i = 0; i < this.searchData.length; i++) {
                                let map = this.searchData[i];
                                let stock = map.stock;
                                let stockName = map.stockName;
                                let result = `${stockName}(${stock})`;
                                results.push(result);
                            }

                            cb(results);
                        }
                    });
            } else {
                cb([]);
            }
        },

        //选择比赛
        handleSelectRace(name, itemIndex) {
            console.log("Selected item:", name);
            this.dataForm.raceName = name;
            this.dataForm.raceId = "";
        },

        handleClose() {

        },

        getCommonMarginBottom(length, index) {
            if (index == length - 1) {
                return 0;
            } else {
                return 20;
            }
        },

        // 兑换事件
        exchangeEvent(index) {
            this.dataForm.dialogVisible = true;
            this.dataForm.exchangeIndex = index;
        },

        //确认兑换
        confirmExchangeAction() {
            this.dataForm.dialogVisible = false;
            let item = this.dataList[this.dataForm.exchangeIndex];
            console.log("打印兑换参数：", item);
            if (item.isReceiveAccount == true) {
                item.receiveType = 2;
            } else {
                item.receiveType = 1;
            }
            this.api.race.awardExchange({
                dataId: item.dataId,
                friendId: item.friendId,
                receiveType: item.receiveType
            }).then(({ data }) => {
                console.log("兑换返回结果：", data)
                if (data && data.code === 200) {
                    console.log("提交成功");
                    this.$message({
                        type: 'info',
                        message: `兑换成功`,
                    });
                    this.getDataList();
                } else {
                    console.log("提交失败");
                }
            })
        }
    }
}
</script>

<style scoped>

.custom-switch-font .el-switch__label {
    font-size: 16px;
    /* 设置文字字号为16px */
}

.my-btn {
    width: 100px;
    margin-bottom: 20px;
    margin-top: 5px;
    margin-right: 10px;
}

.my-max-btn {
    width: 150px;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-right: 20px;
}

.avatar {
    width: 30px;
    height: 30px;
}

.dialog-footer {
    text-align: right;
    padding: 10px;
}

.common {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    /*添加底部间距 */
    height: 100%;
}

.name-item {
    padding: 10px;
}
</style>