<template>
    

    <el-menu class="menu_nav" default-active="2" mode="horizontal" style="height: 45px; padding-top: 10px; padding-right: 10px;"
            background-color="#FFFFFF">
            <div style="height: 45px;" v-for="item in dataList">
                <button class="text-button" @click="logout()">{{item.name}}</button>
            </div>
        </el-menu>
</template>

<script>
import { MessageBox } from 'element-ui';
import { clearLoginInfo } from '@/utils'

export default {
    data() {
        return {
            dataList: [
                {
                    id: "1",
                    name: "退出登录",//"退出登录",
                },
            ],
        }
    },
    methods: {
        logout() {
            MessageBox.alert('确定退出登录？', '提示', {
                    confirmButtonText: '确定',
                    callback: action => {
                        this.api.account.logout().then(({ data }) => {
                        if (data && data.code === 200) {
                            clearLoginInfo()
                            this.$router.push({ name: 'login' })
                        }
                    })

                    }
                });
        },
    }
}
</script>

<style scoped>

.nav_style {
    display: flex;
    width: 100%;
    height: 45px;
    justify-content: center;
}

.menu_nav {
    display: flex;
    margin-left: 0px;
    justify-content: right;
    width: 100%;
    height: 45px;
}

.text-button {
  border: none;         /* 移除边框 */
  background-color: transparent; /* 透明背景 */
  padding: 0;           /* 移除内边距 */
  font-size: 16px;      /* 设置字体大小 */
  cursor: pointer;      /* 将鼠标样式设置为指针形状 */
  color: #666666;         /* 设置文字颜色 */
}
 
.text-button:hover {
  text-decoration: underline; /* 鼠标悬停时下划线 */
}

</style>